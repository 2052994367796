export default function newsletterSubmit(e) {
  e.preventDefault();

  grecaptcha.ready(function () {
    grecaptcha
      .execute("6LdFJ9oaAAAAALYyk8nnS8t_rxezj1WSBDw84TgD", { action: "submit" })
      .then(async function (captcha) {
        const email = document.querySelector("#emailInput").value;
        const privacyCheck =
          document.querySelector("#checkbox-privacy").checked;

        if (!privacyCheck) {
          showMessage("Πρέπει να αποδεχτείς την πολιτική απορρήτου", "error");
          return;
        }

        if (emailIsValid(email)) {
          try {
            const backendRes = await fetch("/api/v1/mail", {
              method: "POST",
              headers: {
                "Content-type": "application/json",
              },
              body: JSON.stringify({ email, captcha }),
            });

            const res = await backendRes.json();
            if (backendRes.status === 400) throw new Error(res.error);

            if (res) {
              showMessage(
                "Λάβαμε το email σου και θα σε ενημερώσουμε.",
                "success"
              );
              clearInput();
            }
          } catch (e) {
            if (
              e.message === "No captcha" ||
              e.message === "Captcha not verified"
            )
              showMessage(
                "Δεν είναι σωστό το captcha, ξαναπροσπάθησε",
                "error"
              );
            else if (
              e.message === "No @" ||
              e.message.includes("Invalid email")
            )
              showMessage("Το email δεν είναι αποδεκτό", "error");
            else if (e.message.includes("E11000"))
              showMessage(
                "Μας έχεις ξαναδώσει το email σου. Θα σε ενημερώσουμε",
                "success"
              );
            else showMessage(e.message, "error");

            clearInput();
          }
        } else {
          showMessage("Το email δεν είναι αποδεκτό", "error");
        }
      });
  });
}

function showMessage(message, type) {
  const messageDiv = document.querySelector("#information-messages");
  const p = document.createElement("p");

  p.innerHTML = message;
  p.style.color = type === "error" ? "#F85C50" : "rgba(0, 132, 140, 1)";
  p.style.background =
    type === "error" ? "rgba(248, 92, 80, 0.1)" : "rgba(0, 132, 140, .1)";
  p.style.padding = "10px 20px";
  p.style.width = "max-content";
  p.style.maxWidth = "50%";
  p.style.borderRadius = "5px";
  p.style.marginBottom = "30px";
  p.setAttribute("id", "custom-message");
  messageDiv.appendChild(p);

  setTimeout(() => {
    messageDiv.removeChild(messageDiv.childNodes[0]);
  }, 5000);
}

function clearInput() {
  document.querySelector("#emailInput").value = "";
}

function emailIsValid(email) {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
}
