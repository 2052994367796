const bodyScrollLock = require("body-scroll-lock");

// Scss imports
import "./scss";

// User Libs
import showOnScrollAnimation from "./animation/show-on-scroll";
import newsletterSubmit from "./utils/form";

const app = async () => {
  showOnScrollAnimation();
  bodyScrollLock.disableBodyScroll("body");
  await new Promise((r) => setTimeout(r, 800));

  bodyScrollLock.enableBodyScroll("body");
  const loadingContainer = document.getElementById("loading");
  loadingContainer.classList.add("fade-out");
  loadingContainer.addEventListener("animationend", (event) => {
    loadingContainer.style.display = "none";
  });

  const newsletterForm = document.querySelector("#newsletter");
  newsletterForm.addEventListener("submit", newsletterSubmit);
};

/**
HELPER FUNCTIONS
 * **/

// document.addEventListener("readystatechange", (e) => {
//   const docState = document.readyState;
//   if (docState === "complete") {
//     bodyScrollLock.enableBodyScroll("body");
//     const loadingContainer = document.getElementById("loading");
//     loadingContainer.classList.add("fade-out");
//     loadingContainer.addEventListener("animationend", (event) => {
//       loadingContainer.style.display = "none";
//     });
//     app();
//   } else {
//     bodyScrollLock.disableBodyScroll("body");
//   }
//   document.removeEventListener("readystatechange", () => {});
// });

app();
