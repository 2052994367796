export default function showOnScrollAnimation() {
  // Query for elements to show on scroll
  var elementsToShow = document.querySelectorAll(".show-on-scroll");
  // Add scroll event listener
  var scroll =
    window.requestAnimationFrame ||
    function (callback) {
      window.setTimeout(callback, 1000 / 60);
    };

  //Loop that checks for elements
  const loop = () => {
    elementsToShow.forEach((e) => {
      if (isElementInViewport(e)) {
        e.classList.add("is-visible");
      }
    });

    scroll(loop);
  };
  loop();
}

// Check if element is in viewport
const isElementInViewport = (el) => {
  // special bonus for those using jQuery
  if (typeof jQuery === "function" && el instanceof jQuery) {
    el = el[0];
  }
  var rect = el.getBoundingClientRect();
  return (
    (rect.top <= 0 && rect.bottom >= 0) ||
    (rect.bottom >=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.top <=
        (window.innerHeight || document.documentElement.clientHeight)) ||
    (rect.top >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight))
  );
};
